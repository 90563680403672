import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  PLATFORM_ID,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'src/app/shared/models/order.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from 'src/app/shared/service/order.service';
import { Utils } from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-order',
  templateUrl: './update-order.component.html',
  styleUrls: ['./update-order.component.scss'],
})
export class UpdateOrderComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: false }) UpdateOrder: TemplateRef<any>;
  @Input() order: Order;

  public closeResult: string;
  public modalOpen: boolean = false;

  listOrderStatus = Utils.orderStatus;

  public productForm: FormGroup;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private _orderService: OrderService,
    private _activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.productForm = this.fb.group({
      orderStatus: [this.order.orderStatus],

      shippingFee: [this.order.shippingFee ? this.order.shippingFee : 0],
    });

    console.log(this.productForm);
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.UpdateOrder, {
          size: 'lg',
          ariaLabelledBy: 'modal-basic-title',
          windowClass: 'UpdateOrder',
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  onSubmit(): void {
    console.log(this.productForm.value);

    const tmpOrder = this.productForm.value;
    tmpOrder.totalAmount = this.order.productAmount
      ? this.order.productAmount
      : 0 + tmpOrder.shippingFee;

    this._orderService.updateOder(this.order.orderId, tmpOrder).then(() => {
      this._toastrService.success('Đã cập nhật  thành công !');

      this.productForm.reset();
      this._changeDetectorRef.markForCheck();
      this.activeModal.close(); //
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
