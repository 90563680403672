<div class="container-fluid" *ngIf="product$ | async as product">
  <div class="card">
    <div class="row product-page-main card-body">
      <div class="col-xl-4">
        <section>
          <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar class="product-slick">
            <ng-container *ngFor="let image of product?.images; index as i">
              <ng-template carouselSlide [id]="i">
                <div>
                  <img [src]="image.src" [alt]="image.alt" class="img-fluid" />
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

          <div class="col-12 p-0" *ngIf="product?.images.length > 1">
            <div class="slider-nav">
              <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                <ng-container *ngFor="let image of product?.images; index as i">
                  <ng-template carouselSlide [id]="i">
                    <div class="owl-thumb" [class.active]="i == activeSlide">
                      <img [src]="image.src" [alt]="image.alt" class="img-fluid"
                        (click)="owlCar.to((activeSlide = i.toString()))" />
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </section>
      </div>
      <div class="col-xl-8">
        <div class="product-page-details product-right mb-0">
          <h2>{{ product.title }}</h2>
          <ngb-rating [rate]="3" class="rating-size-product"></ngb-rating>
          <hr />
          <h6 class="product-title">Chi tiết sản phẩm</h6>
          <p class="mb-0">
            {{ product.description }}
          </p>
          <div class="product-price digits mt-2">
            <h4>
              <del *ngIf="product?.discount">{{ product?.price * productService.Currency?.price | currency:
                productService.Currency?.currency:'symbol' }}</del>
              <span>{{ product?.discount }}% giảm</span>
            </h4>
            <h3>
              {{ (product?.price | discount:product) * productService.Currency?.price | currency:
              productService.Currency?.currency:'symbol' }}
            </h3>
          </div>

          <hr />


          <div class="add-product-form">
            <h6 class="product-title">Số sản phẩm đang có : {{ product.stock}} </h6>

          </div>

          <div class="m-t-15">
            <button class="btn btn-primary m-r-10" (click)="deleteProduct()">
              Xóa
            </button>

            <button class="btn btn-secondary" (click)="editProduct()"> > Chỉnh sửa </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
