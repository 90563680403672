import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { User } from 'src/app/shared/models/user.model';
import firebase from 'firebase/compat/app';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
@Component({
  selector: 'app-edit-about',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class EditAboutUsComponent implements OnInit {
  @ViewChild('uploadTask', { static: false }) uploadTask: UploadTaskComponent;
  @ViewChild('uploadTask0', { static: false }) uploadTask0: UploadTaskComponent;
  @ViewChild('uploadTask1', { static: false }) uploadTask1: UploadTaskComponent;
  @ViewChild('uploadTask2', { static: false }) uploadTask2: UploadTaskComponent;
  @ViewChild('uploadTask3', { static: false }) uploadTask3: UploadTaskComponent;
  @ViewChild('uploadTask4', { static: false }) uploadTask4: UploadTaskComponent;
  @ViewChild('uploadTask5', { static: false }) uploadTask5: UploadTaskComponent;
  @ViewChild('uploadTask6', { static: false }) uploadTask6: UploadTaskComponent;
  @ViewChild('uploadTask7', { static: false }) uploadTask7: UploadTaskComponent;
  @ViewChild('uploadTask8', { static: false }) uploadTask8: UploadTaskComponent;
  @ViewChild('uploadTask9', { static: false }) uploadTask9: UploadTaskComponent;

  public url = [];

  public images = [];

  public productForm: FormGroup;
  public counter: number = 1;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };
  currentUrl = 'assets/images/about-main.jpg';
  currentUrlImage;

  downloadedUrl: string = null;
  user: User;

  constructor(
    private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService,
    private _store: Store,
    private _activatedRoute: ActivatedRoute,
    private _afs: AngularFirestore
  ) {}

  onSubmit(): void {
    if (this.downloadedUrl) {
      const blog = this.productForm.value;
      blog.mainImageUrl = this.downloadedUrl;
      blog.images = this.images;
      this._afs
        .collection('companyInfo')
        .doc('1')
        .update({ ...blog });
      this.productForm.reset();
      this.toastrService.success('Đã cập nhật thành công !', 'Thông báo ');
      this.downloadedUrl = null;
      this.productForm.reset();
      this._changeDetectorRef.markForCheck();
    } else {
      this.toastrService.warning('Bạn cần chọn hình ảnh !');
    }
  }

  //FileUpload
  chooseUpload(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentUrl = reader.result.toString();
    };

    // start upload
    this.uploadTask.startUpload(event.target.files[0]);
  }

  getDownloadedUrlManin(value): void {
    this.downloadedUrl = value;
  }

  blog$: Observable<any>;
  blog: any;

  ngOnInit() {
    this.blog$ = this._afs.collection('companyInfo').doc('1').valueChanges();

    this.blog$.subscribe((data) => {
      this.blog = data;
      this.images = this.blog.images;
      this.currentUrlImage = this.blog.images[0].src;
      this.url = this.blog.images.map((a) => {
        return { img: a.src };
      });
      const urlRemain = 9 - this.url.length;
      if (urlRemain > 0) {
        for (let i = 0; i < urlRemain; i++) {
          this.url.push({
            img: 'assets/images/dashboard/product/1.jpg',
          });
        }
      }
      if (this.blog.mainImageUrl) {
        this.currentUrl = this.blog.mainImageUrl;
        this.downloadedUrl = this.blog.mainImageUrl;
      }
      // this.currentUrl = this.blog.mainImageUrl ? this.blog.mainImageUrl : 'assets/images/prod-bg.jpg';
      // this.downloadedUrl = this.blog.mainImageUrl ? this.blog.mainImageUrl : 'assets/images/prod-bg.jpg';

      this.productForm = this.fb.group({
        title: [
          this.blog.title,
          [
            Validators.required,
            Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
          ],
        ],

        content: [this.blog.content],
      });
      this._changeDetectorRef.markForCheck();
    });
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
      this.currentUrl = reader.result.toString();
    };

    // start
    // this.uploadTask.startUpload(event.target.files[0]);
    switch (i) {
      case 0:
        this.uploadTask0.startUpload(event.target.files[0]);
        break;
      case 1:
        this.uploadTask1.startUpload(event.target.files[0]);
        break;
      case 2:
        this.uploadTask2.startUpload(event.target.files[0]);
        break;
      case 3:
        this.uploadTask3.startUpload(event.target.files[0]);
        break;
      case 4:
        this.uploadTask4.startUpload(event.target.files[0]);
        break;
      case 5:
        this.uploadTask5.startUpload(event.target.files[0]);
        break;
      case 6:
        this.uploadTask6.startUpload(event.target.files[0]);
        break;
      case 7:
        this.uploadTask7.startUpload(event.target.files[0]);
        break;
      case 8:
        this.uploadTask8.startUpload(event.target.files[0]);
        break;
      default:
        break;
    }
  }

  getDownloadedUrl(value, i): void {
    const image = {
      id: i,
      image_id: i,
      alt: '',
      src: value,
    };

    this.images[i] = image;
  }
}
