
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditAboutUsComponent } from './aboutus.component';


const routes: Routes = [
  {
    path: '',
    component: EditAboutUsComponent,
    data: {
      title: "Sửa thông tin Giới thiệu Cty",
      breadcrumb: "Giới thiệu"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutUsRoutingModule { }
