<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form
            *ngIf="productForm"
            (ngSubmit)="onSubmit()"
            class="needs-validation"
            [formGroup]="productForm"
            novalidate
          >
            <div class="row product-adding">
              <div class="col-xl-12">
                <div class="row">
                  <div class="col-xl-10 col-10">
                    <upload-task
                      #uploadTask
                      dirName="about"
                      showImageUploaded="false"
                      showImageDownload="false"
                      (getDownloadUrl)="getDownloadedUrlManin($event)"
                    >
                    </upload-task>
                    <br />

                    <img class="img-fluid" [src]="currentUrl" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="add-product">
                  <div class="col-xl-2 col-2">
                    <ul class="file-upload-product">
                      <li>
                        <div class="box-input-file">
                          <input
                            class="upload"
                            type="file"
                            (change)="chooseUpload($event)"
                          />
                          <img
                            class="imgUpload img-fluid image_zoom_1"
                            alt=""
                            src="assets/images/upload.png"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 mt-5">
                <div class="add-product-form">
                  <div class="form-group row">
                    <label
                      for="validationCustom01"
                      class="col-xl-2 col-sm-4 mb-0"
                      >Tựa đề :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom01"
                      type="text"
                      [formControl]="productForm.controls['title']"
                      required
                    />
                    <div
                      *ngIf="
                        productForm.controls.title.touched &&
                        productForm.controls.title.errors?.required
                      "
                      class="text text-danger"
                    >
                      Cần nhập tựa đề.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row product-adding mt-5">
              <div class="col-xl-12">
                <div class="add-product-form">
                  <div class="form-group row">
                    <label for="content" class="col-xl-12">Nội dung :</label>
                    <div class="col-xl-12">
                      <quill-editor
                        id="content"
                        [formControl]="productForm.controls['content']"
                        [modules]="quillModules"
                      >
                      </quill-editor>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <upload-task
                    #uploadTask0
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 0)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask1
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 1)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask2
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 2)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask3
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 3)"
                  >
                  </upload-task>
                  <upload-task
                    #uploadTask4
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 4)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask5
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 4)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask6
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 4)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask7
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 4)"
                  >
                  </upload-task>

                  <upload-task
                    #uploadTask8
                    dirName="product"
                    showImageUploaded="false"
                    showImageDownload="false"
                    (getDownloadUrl)="getDownloadedUrl($event, 4)"
                  >
                  </upload-task>

                  <div class="col-xl-3" *ngFor="let item of url; let i = index">
                    <div class="box-input-file">
                      <input
                        class="upload"
                        type="file"
                        (change)="readUrl($event, i)"
                      />
                      <img
                        width="auto"
                        height="100px"
                        class="imgUpload"
                        alt=""
                        [src]="item.img"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div
                    class="product-buttons col-sm-10 offset-xl-10 offset-sm-8"
                  >
                    <button type="submit" class="btn btn-primary mr-1">
                      Lưu
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
