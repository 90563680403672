import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { EditOrderComponent } from 'src/app/shared/components/modal/edit-order/edit-order.component';

import { Order } from 'src/app/shared/models/order.model';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { OrderService } from 'src/app/shared/service/order.service';
import { Utils } from 'src/app/shared/utils';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  @ViewChild('editOrder') EditOrder: EditOrderComponent;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  orders$: Observable<Order[]>;
  order = [];
  temp = [];
  selectedOrderId;

  selected = [];

  columns: any[] = [
    { prop: 'Mã đơn hàng' },
    { prop: 'Ngày' },
    { prop: 'Tổng tiền' },
    { prop: 'Trạng thái đơn hàng' },
  ];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  constructor(
    private _authService: AuthService,
    private _orderService: OrderService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.orders$ = this._orderService.getOrders();
    this.orders$.subscribe((orders: Order[]) => {
      this.order = orders.map((item: Order) => this.createOrderItemTable(item));
      console.log(this.order);
      // cache our list
      this.temp = [...orders];
    });
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.EditOrder.openModal();
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

  updateFilter(event) {
    console.log(this.temp);
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.orderId.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.order = temp.map((item: Order) => this.createOrderItemTable(item));
    // this.order = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  createOrderItemTable(item: Order): any {
    return {
      orderId: item.orderId,
      'Mã đơn hàng': item.orderId,
      Ngày: Utils.convertDateString(item.timestamp.toDate()),
      'Tổng tiền': item.totalAmount,
      'Phương thức thanh toán': item.paymentMethod,
      'Trạng thái đơn hàng': this._orderService.getOrderStatusName(
        item.orderStatus
      ),
    };
  }

  edit(row: any) {
    console.log(row);
    this.selectedOrderId = row.orderId;
    if (this.selectedOrderId) {
      this.EditOrder.openModal();
    }
  }
}
