import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { Category, Type } from 'src/app/shared/models/category.model';
import { Product } from 'src/app/shared/models/product.model';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  @ViewChild('uploadTask0', { static: false }) uploadTask0: UploadTaskComponent;
  @ViewChild('uploadTask1', { static: false }) uploadTask1: UploadTaskComponent;
  @ViewChild('uploadTask2', { static: false }) uploadTask2: UploadTaskComponent;
  @ViewChild('uploadTask3', { static: false }) uploadTask3: UploadTaskComponent;
  @ViewChild('uploadTask4', { static: false }) uploadTask4: UploadTaskComponent;

  public productForm: FormGroup;
  public counter: number = 1;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };
  currentUrl = 'assets/images/pro3/1.jpg';
  public url = [];

  public images = [];

  variants = [];

  types$: Observable<Type[]>;
  categories$: Observable<Category[]>;

  downloadedUrl: string = null;
  productId = '';
  product$: Observable<Product>;
  product: Product;

  constructor(
    private fb: FormBuilder,
    private _categoriesService: CategoriesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private productService: ProductService,
    private _activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.types$ = this._categoriesService.getTypes();
    this.categories$ = this._categoriesService.getCategories();
    this.productId = this._activatedRoute.snapshot.paramMap.get('productId');

    this.product$ = this.productService.getProduct(this.productId);
    this.product$.subscribe((data) => {
      this.product = data;
      this.currentUrl = this.product.images[0].src;
      this.images = this.product.images;
      this.url = this.product.images.map((a) => {
        return { img: a.src };
      });
      const urlRemain = 5 - this.url.length;
      if (urlRemain > 0) {
        for (let i = 0; i < urlRemain; i++) {
          this.url.push({
            img: 'assets/images/dashboard/product/1.jpg',
          });
        }
      }

      this.counter = this.product.stock;

      this.productForm = this.fb.group({
        category: [this.product.category, Validators.required],
        type: [this.product.type, Validators.required],
        title: [
          this.product.title,
          [
            Validators.required,
            Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
          ],
        ],
        price: [
          this.product.price,
          [
            Validators.required,
            Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
          ],
        ],
        sale: [this.product.sale],
        discount: [
          this.product.discount,
          [Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')],
        ],
        stock: [
          this.product.stock,
          [Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')],
        ],
        tags: [this.product.tags],
        new: [this.product.new],
        code: [this.product.code],

        description: [this.product.description],
        brand: [this.product.brand],
      });
      this._changeDetectorRef.markForCheck();
    });
  }

  onTypeChange(selectedItemId: string) {
    this.categories$ =
      this._categoriesService.getCategoriesByType(selectedItemId);
    this._changeDetectorRef.markForCheck(); //
  }

  onSubmit(): void {
    if (this.images.length > 0) {
      const product = this.productForm.value;
      product.images = this.images;
      product.variants = this.variants;
      const collection = [this.productForm.value.category];
      product.collection = collection;
      this.productService.updateProduct(this.productId, product).then(() => {
        this.toastrService.success('Đã cập nhật sản phẩm thành công !');
        this.images = [];
        this.variants = [];
        this.productForm.reset();
        this._changeDetectorRef.markForCheck();
      });
    } else {
      this.toastrService.warning('Bạn cần chọn hình ảnh cho sản phẩm!');
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
      this.currentUrl = reader.result.toString();
    };

    // start
    // this.uploadTask.startUpload(event.target.files[0]);
    switch (i) {
      case 0:
        this.uploadTask0.startUpload(event.target.files[0]);
        break;
      case 1:
        this.uploadTask1.startUpload(event.target.files[0]);
        break;
      case 2:
        this.uploadTask2.startUpload(event.target.files[0]);
        break;
      case 3:
        this.uploadTask3.startUpload(event.target.files[0]);
        break;
      case 4:
        this.uploadTask4.startUpload(event.target.files[0]);
        break;
      default:
        break;
    }
  }

  getDownloadedUrl(value, i): void {
    const image = {
      id: i,
      image_id: i,
      alt: '',
      src: value,
    };

    const variant = {
      id: i,
      variant_id: i,
      color: '',
      image_id: i,
    };

    this.images[i] = image;
    this.variants[i] = variant;
  }
}
