<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright 2021 © Công ty TNHH Dược Phẩm Phan Rang.</p>
        </div>
        <div class="col-md-6">
            <p class="pull-right mb-0">www.phanrangpharma.com<i class="fa fa-heart"></i></p>
        </div>
    </div>
</div>
