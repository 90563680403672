<div class="container-fluid">
  <div class="row">
    <div class="col-xl-10">
      <!-- <select (change)="onCategoryChange($event.target.value)" *ngIf="categories$ | async as categories"
        class="form-control digits col-xl-9 col-sm-7">
        <option>Chọn Chuyên mục </option>
        <option *ngFor="let category of categories" [value]="category.id">
          {{ category.title }}
        </option>
      </select> -->
    </div>

    <div class="btn-popup pull-right">
      <a class="btn btn-primary" routerLink="../add-blog"> Thêm Tin tức </a>
    </div>
  </div>

  <div class="row products-admin ratio_asos" *ngIf="blog_list.length > 0">
    <!--Blog List start-->
    <div class="col-xl-12 col-lg-8 col-md-7" *ngFor="let blog of blog_list">
      <div class="row blog-media">
        <div class="col-xl-6">
          <div class="blog-left">
            <a [routerLink]="'/pages/blog/details'"
              ><img width="550px" height="auto" [src]="blog.imageUrl" alt=""
            /></a>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="blog-right">
            <div>
              <h6>Ngày : {{ blog.timestamp.toDate() | date: "dd/MM/yyyy" }}</h6>
              <a [routerLink]="'/pages/blog/details'">
                <h4>{{ blog.title }}</h4>
              </a>
              <ul class="post-social">
                <li>Thêm bởi : {{ blog.author.displayName }}</li>
                <!-- <li><i class="fa fa-heart"></i> 5 Hits </li>
                <li><i class="fa fa-comments"></i> 10 Comment</li> -->
              </ul>
              <p class="mt-1">{{ blog.description }}</p>
            </div>
            <div></div>

            <ul>
              <li>
                <a
                  href="javascript:void(0)"
                  [routerLink]="'../edit-blog/' + blog.id"
                  class="btn"
                  type="button"
                  data-original-title=""
                  title=""
                >
                  <i class="icon-pencil-alt"></i>
                </a>
                <!-- <a href="javascript:void(0)" [routerLink]="'../product-blog/' + blog.id" class="btn" type="button"
                  data-original-title="" title="">
                  <i class="icon-search"></i>
                </a> -->
                <button
                  class="btn"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  data-original-title=""
                  title=""
                  (click)="deleteProduct(blog.id)"
                >
                  <i class="icon-trash"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <!--Blog List start-->
  </div>
</div>
