// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDeHnRKJ0L_LeUw-qFiDhrHIr5E4TrIxtM',
    authDomain: 'ctyduocphanrang.firebaseapp.com',
    projectId: 'ctyduocphanrang',
    storageBucket: 'ctyduocphanrang.appspot.com',
    messagingSenderId: '1054099638850',
    appId: '1:1054099638850:web:4ce65731580466d9617bdb',
    measurementId: 'G-PN6WR8V9X8',

    // apiKey: 'AIzaSyBabrpv7MrldwxBIr5z3xvisBXVd-2ovzQ',
    // authDomain: 'thuexeapp.firebaseapp.com',
    // databaseURL: 'https://thuexeapp.firebaseio.com',
    // projectId: 'thuexeapp',
    // storageBucket: 'thuexeapp.appspot.com',
    // messagingSenderId: '571522412933',
    // appId: '1:571522412933:web:f38eff68f9da37d48794ec',
    // measurementId: 'G-L63MWVKXV8',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
