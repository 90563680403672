<ng-template class="theme-modal" #content let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body">
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row">
        <div class="col-lg-12">
          <app-order-detail [orderId]="orderId"></app-order-detail>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-red"
        (click)="modal.close('Cross click')"
      >
        Đóng
      </button>
    </div>
  </div>
</ng-template>
