import { Observable } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { ToastrService } from 'ngx-toastr';

import { Category, Type } from 'src/app/shared/models/category.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @ViewChild('uploadTask', { static: false }) uploadTask: UploadTaskComponent;

  public closeResult: string;
  public categories$: Observable<any[]>;
  categories = [];

  category = {
    title: '',
    imageUrl: '',
    imageUrlHtml: '',
    typeId: '',
  };

  types$: Observable<Type[]>;

  fileUpload: File = null;
  downloadedUrl: string = null;

  constructor(
    private modalService: NgbModal,
    private _categoriesService: CategoriesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.types$ = this._categoriesService.getTypes();
    this.categories$ = this._categoriesService.getCategories();
    this.categories$.subscribe((cates) => {
      this.categories = cates;
    });
  }

  onUserRowSelect(event) {}

  onEdit(event) {
    this._categoriesService.updateCategory(event.newData.id, event.newData);
    event.confirm.resolve();
    // if (window.confirm('Are you sure you want to delete?')) {
    //   event.confirm.resolve();
    // } else {
    //   event.confirm.reject();
    // }
  }

  onDeleteConfirm(event) {
    if (window.confirm('Bạn có muốn xóa không?')) {
      this._categoriesService.deleteCategory(event.data.id);
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }

  getDownloadedUrl(value): void {
    this.downloadedUrl = value;
    this.fileUpload = null;
    // this.categoryForm.controls['imageUrl'].setValue(value);
  }

  onChooseFile(files: FileList): void {
    this.fileUpload = null;
    this.downloadedUrl = null;

    this.fileUpload = files.item(0);

    if (this.fileUpload.name) {
      this.uploadTask.startUpload(this.fileUpload);
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.category.imageUrl = this.downloadedUrl;
          this.category.imageUrlHtml = `<img src='${this.downloadedUrl}' class='imgTable'>`;
          this._categoriesService.addCategory(this.category);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public settings = {
    selectMode: 'multi',
    actions: {
      position: 'right',
      add: false,
    },
    delete: {
      confirmDelete: true,

      deleteButtonContent: 'Delete data',
      saveButtonContent: 'save',
      cancelButtonContent: 'cancel',
    },
    add: {
      confirmCreate: true,
    },
    edit: {
      confirmSave: true,
    },
    columns: {
      imageUrlHtml: {
        title: 'Image',
        type: 'html',
      },
      title: {
        title: 'Name',
      },
    },
  };
}
