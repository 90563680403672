
import { NgModule } from '@angular/core';

import { UploadTaskComponent } from './upload-task.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    UploadTaskComponent
  ],
  exports: [
    UploadTaskComponent
  ],
  imports: [
    CommonModule,
    NgbModule
  ]
})
export class UploadTaskModule {
}
