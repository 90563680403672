<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">
        <div class="card-header">
          <h5>Add User</h5>
        </div>
        <div class="card-body tab2-card">
          <ngb-tabset class="tab-coupon">
            <ngb-tab title="Account">
              <ng-template ngbTabContent>
                <div
                  class="tab-pane fade active show"
                  id="account"
                  role="tabpanel"
                  aria-labelledby="account-tab"
                >
                  <form
                    [formGroup]="accountForm"
                    class="needs-validation user-add"
                    novalida
                  >
                    <h4>Account Details</h4>
                    <div class="form-group row">
                      <label for="validationCustom0" class="col-xl-3 col-md-4"
                        ><span>*</span> First Name</label
                      >
                      <input
                        class="form-control col-xl-8 col-md-7"
                        id="validationCustom0"
                        type="text"
                        required=""
                      />
                    </div>
                    <div class="form-group row">
                      <label for="validationCustom1" class="col-xl-3 col-md-4"
                        ><span>*</span> Last Name</label
                      >
                      <input
                        class="form-control col-xl-8 col-md-7"
                        id="validationCustom1"
                        type="text"
                        required=""
                      />
                    </div>
                    <div class="form-group row">
                      <label for="validationCustom2" class="col-xl-3 col-md-4"
                        ><span>*</span> Email</label
                      >
                      <input
                        class="form-control col-xl-8 col-md-7"
                        id="validationCustom2"
                        type="text"
                        required=""
                      />
                    </div>
                    <div class="form-group row">
                      <label for="validationCustom3" class="col-xl-3 col-md-4"
                        ><span>*</span> Password</label
                      >
                      <input
                        class="form-control col-xl-8 col-md-7"
                        id="validationCustom3"
                        type="password"
                        required=""
                      />
                    </div>
                    <div class="form-group row">
                      <label for="validationCustom4" class="col-xl-3 col-md-4"
                        ><span>*</span> Confirm Password</label
                      >
                      <input
                        class="form-control col-xl-8 col-md-7"
                        id="validationCustom4"
                        type="password"
                        required=""
                      />
                    </div>
                  </form>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab>
              <ng-template ngbTabTitle
                >Permission
                <ng-template ngbTabContent> </ng-template>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
          <div class="pull-right">
            <button type="button" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
