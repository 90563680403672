import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/shared/service/auth/auth.service';

import firebase from 'firebase/compat/app';
import { User } from 'src/app/shared/models/user.model';
import { ToastrService } from 'ngx-toastr';
import { map, mergeMap } from 'rxjs/operators';
import { UpdateCurrentUserSignIned } from 'src/app/shared/service/auth/auth.actions';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public registerForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _store: Store,
    private _afs: AngularFirestore,
    private toastrService: ToastrService
  ) {
    this.createLoginForm();
    // this.createRegisterForm();
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: [''],
    });
  }
  // createRegisterForm() {
  //   this.registerForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //     confirmPassword: [''],
  //   });
  // }

  ngOnInit() {}

  loginWithGoogle(): void {
    const provider = new firebase.auth.GoogleAuthProvider();
    this._authService.signInWithProvider(provider);
    // this.store.dispatch(new SignInWithProvider(provider));
  }

  loginWithFacebook(): void {
    const provider = new firebase.auth.FacebookAuthProvider();
    this._authService.signInWithProvider(provider);
    // this.store.dispatch(new SignInWithProvider(provider));
  }

  signInWithEmail(): void {
    // Return if the form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Sign in
    const data = this.loginForm.value;
    let userData: User;
    this._authService
      .signInWithEmail(data.email, data.password)
      .then((result) => {
        // update current login
        this._store.dispatch(new UpdateCurrentUserSignIned(userData));
        // get current path and navigate
        this._router.navigateByUrl('/');
      })
      .catch((_) => {
        this.toastrService.error('Sai email hoặc mật khẩu! ', 'Thông báo');
      });
  }
}
