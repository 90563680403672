import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from './auth.actions';
import { User } from '../../models/user.model';
import { UpdateCurrentRouter } from '../../router.state';

@Injectable()
export class AuthService {
  /**
   * Constructor
   */
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private store: Store
  ) {}

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this.afAuth.signOut().then(() => {
      this.store.dispatch(new Logout());
    });

    // Return the observable
    return of(true);
  }

  getCurrentUserSignIned(): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap((user: any) => {
        // Logged in
        if (user === null) {
          // this.afAuth.signInAnonymously();
          return of(null);
        } else {
          return user.isAnonymous
            ? of(null)
            : this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        }
      })
    );
  }
  // check user logged in?
  checkUserLoggedIn(): void {
    // get user in state store
    this.store
      .select((state) => state.auth.user)
      .subscribe((user: any) => {
        // Logged in
        if (!user) {
          // store current path and redirect to login page
          this.store.dispatch(new UpdateCurrentRouter(this.router.url));
          this.router.navigate(['/sign-in']);
        }
      });
  }

  registerWithEmail(
    name: string,
    email: string,
    password: string
  ): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  signInAnonymously(): any {
    this.afAuth
      .signInAnonymously()
      .then((credential) => {})
      .catch((error) => {});
  }

  signInWithEmail(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logout(): void {
    this.afAuth.signOut().then(() => {
      this.store.dispatch(new Logout());
      this.router.navigateByUrl('/auth/login');
    });
  }

  signInWithProvider(provider: any): any {
    return this.afAuth.signInWithPopup(provider).then((credential) => {
      let userData: User;

      // check if user register with provider
      this.afs
        .doc(`users/${credential.user.uid}`)
        .valueChanges()
        .subscribe((user: User) => {
          if (user) {
            const data: User = {
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
              roles: user.roles,
              emailVerified: credential.user.emailVerified,
              phoneNumber: credential.user.phoneNumber,
              providerId: credential.user.providerData[0]?.providerId,
              creationTime: credential.user.metadata?.creationTime,
              lastSignInTime: credential.user.metadata?.lastSignInTime,
            };
            //TODO: Loop request to firebase
            // this.updateUserData(data);
            // for saving store
            userData = {
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
              roles: user.roles,
            };
          } else {
            const data: User = {
              uid: credential.user.uid,
              displayName: credential.user.displayName,
              photoURL: credential.user.photoURL,
              email: credential.user.email,
              roles: { customer: true },
              emailVerified: credential.user.emailVerified,
              phoneNumber: credential.user.phoneNumber,
              providerId: credential.user.providerData[0]?.providerId,
              creationTime: credential.user.metadata?.creationTime,
              lastSignInTime: credential.user.metadata?.lastSignInTime,
            };

            //TODO: loop request to firebase
            // this.updateUserData(data);

            // for saving store
            userData = {
              uid: credential.user.uid,
              displayName: credential.user.displayName,
              photoURL: credential.user.photoURL,
              email: credential.user.email,
              roles: { customer: true },
            };
          }
        });

      // verify email if have not verified
      if (!credential.user.emailVerified) {
        this.sendEmailVerification();
      }

      // get current path and navigate
      this.store
        .select((state) => state.router)
        .subscribe((path: string) => {
          this.router.navigateByUrl(path);
        });
    });
  }

  async updateEmail(newEmail: string): Promise<any> {
    const user = await this.afAuth.currentUser;
    return user.updateEmail(newEmail);
  }

  async sendEmailVerification(): Promise<any> {
    const user = await this.afAuth.currentUser;
    return user.sendEmailVerification();
  }

  async updatePassword(newPassword: string): Promise<any> {
    const user = await this.afAuth.currentUser;
    return user.updatePassword(newPassword);
  }

  async sendPasswordResetEmail(emailAddress: string): Promise<void> {
    this.afAuth
      .sendPasswordResetEmail(emailAddress)
      .then((_) => {
        // Email sent.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  updateUserData(user: any): any {
    // update user
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );

    return userRef.set(user, { merge: true });
  }

  isAdmin(): Promise<boolean> {
    return new Promise((resolve, reject) =>
      this.afAuth.onAuthStateChanged((user: any) => {
        if (user) {
          user.getIdTokenResult().then((idTokenResult: any) => {
            resolve(idTokenResult.claims.admin);
          });
        } else {
          return resolve(false);
        }
      })
    );
  }
}
