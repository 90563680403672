import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { UpdateCurrentUserSignIned } from './shared/service/auth/auth.actions';
import { AuthService } from './shared/service/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Admin - www.phanrangpharma.com';
  constructor(
    private _store: Store,
    private _authService: AuthService,
    private _router: Router
  ) {
    // check signined and not update state call action update sate auth
    this._store
      .select((state) => state.auth.user)
      .pipe(take(2))
      .subscribe((user: any) => {
        if (user == null) {
          const currentUser = this._authService.getCurrentUserSignIned();
          currentUser.subscribe((_user: any) => {
            if (_user == null) {
              return;
            }
            this._store.dispatch(new UpdateCurrentUserSignIned(_user));
          });
        } else {
          console.log(user.roles.admin);
          if (!user.roles.admin) {
            this._router.navigateByUrl('/auth/login');
          }
        }
      });
  }
}
