export class categoryDB {
    static category = [
        {
            img: "<img src='assets/images/dashboard/product/1.jpg' class='imgTable'>",
            product_name: "Headphones",
            price: "$20.00",
            status: "<i class='fa fa-circle font-success f-12'></i>",
            category: "Electronics",
        },
        {
            img: "<img src='assets/images/dashboard/product/2.jpg' class='imgTable'>",
            product_name: "Honor Mobile",
            price: "$462.00",
            status: "<i class='fa fa-circle font-warning f-12'></i>",
            category: "Electronics",
        },


    ]
}
