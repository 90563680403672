import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/service/user/user.service';
import { userListDB } from 'src/app/shared/tables/list-users';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  public user_list = [];

  constructor(private _userService: UserService) {
    this._userService.getAllUsers().subscribe((users: User[]) => {
      this.user_list = users.map((item: User) =>
        this.createUserItemTable(item)
      );
    });
  }

  public settings = {
    columns: {
      photoURL: {
        title: 'Avatar',
        type: 'html',
      },
      fullname: {
        title: 'Họ tên',
      },
      email: {
        title: 'Email',
      },
      phoneNumber: {
        title: 'Điện thoại',
      },
      lastSignInTime: {
        title: 'Ngày đăng nhập',
      },
      role: {
        title: 'Role',
      },
    },
  };

  ngOnInit() {}

  createUserItemTable(item: User): any {
    return {
      photoURL: item.photoURL ? "<img src=tem.photoURL class='imgTable'>" : ' ',
      fullname: item.fullname,
      email: item.email,
      phoneNumber: item.phoneNumber,
      lastSignInTime: Utils.convertDateString(item.lastSignInTime),
      role: item.roles.admin
        ? 'admin'
        : item.roles.customer
        ? 'khách hàng '
        : '',
    };
  }
}
