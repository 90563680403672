<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-5 p-0 card-left">
          <div class="card bg-primary">
            <div class="logo-wrapper">
              <a [routerLink]="'/dashboard/default'">
                <img
                  class="blur-up lazyloaded"
                  src="assets/images/dashboard/logo.png"
                  alt=""
              /></a>
            </div>
            <div class="single-item">
              <p></p>

              <br />
              <br />

              <p>Công ty TNHH Dược Phẩm Phan Rang - www.phanrangpharma.com</p>

              <p>TRANG QUẢN TRỊ DỮ LIỆU</p>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <div class="col-md-7 p-0 card-right">
          <div class="card tab2-card">
            <div class="card-body">
              <ngb-tabset class="tab-coupon mb-0">
                <ngb-tab>
                  <ng-template ngbTabTitle
                    ><i class="icon-user mr-2"></i>Đăng nhập</ng-template
                  >
                  <ng-template ngbTabContent>
                    <div
                      class="tab-pane fade active show"
                      id="account"
                      role="tabpanel"
                      aria-labelledby="account-tab"
                    >
                      <form
                        (ngSubmit)="signInWithEmail()"
                        [formGroup]="loginForm"
                        class="form-horizontal auth-form"
                        novalidate=""
                      >
                        <div class="form-group">
                          <input
                            required=""
                            name="loginForm[email]"
                            [formControl]="loginForm.controls['email']"
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            required=""
                            name="loginForm[password]"
                            [formControl]="loginForm.controls['password']"
                            type="password"
                            class="form-control"
                            placeholder="Mật khẩu "
                          />
                        </div>
                        <div class="form-terms">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlAutosizing"
                            />
                            <!-- <label class="custom-control-label" for="customControlAutosizing">Remember me</label> -->
                            <a
                              href="javascript:void(0)"
                              class="btn btn-default forgot-pass"
                              >Quên mật khẩu</a
                            >
                          </div>
                        </div>
                        <div class="form-button">
                          <button class="btn btn-primary" type="submit">
                            Đăng nhập
                          </button>
                        </div>
                        <div class="form-footer">
                          <!-- <span>Hoặc đăng nhập bằng</span> -->
                          <ul class="social">
                            <!-- <li><a class="icon-facebook" href="javascript:void(0)" (click)="loginWithFacebook()"></a>
                            </li>
                            <li><a class="icon-google" href="javascript:void(0)" (click)="loginWithGoogle()"></a></li> -->
                            <!-- <li><a class="icon-instagram" href="javascript:void(0)"></a>
                            </li>
                            <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                            </li> -->
                          </ul>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
      <!-- <a [routerLink]="'/dashboard/default'" class="btn btn-primary back-btn">
        <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
        back
      </a> -->
    </div>
  </div>
</div>
