import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { AuthService } from '../../service/auth/auth.service';
import { NavService } from '../../service/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;

  user: User = null;
  isAdmin = false;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private _store: Store,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() {
    // Subscribe to user changes
    this._store
      .select((state) => state.auth.user)
      .pipe(take(2))
      .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
    this._authService.isAdmin().then((admin) => {
      this.isAdmin = admin;
    });
  }

  logout() {
    this._authService.logout();
  }
}
