import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddBlogComponent } from './add-blog/add-blog.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogCategoryComponent } from './category/category.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: BlogListComponent,
        data: {
          title: 'Danh sách   ',
          breadcrumb: 'Danh sách tin tức ',
        },
      },

      {
        path: 'edit-blog/:blogId',
        component: EditBlogComponent,
        data: {
          title: 'Sửa Tin tức ',
          breadcrumb: 'Sửa Tin tức ',
        },
      },
      {
        path: 'add-blog',
        component: AddBlogComponent,
        data: {
          title: 'Thêm mới Tin tức  ',
          breadcrumb: 'Thêm Tin tức ',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogsRoutingModule {}
