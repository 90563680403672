<div class="container-fluid">
  <div class="row">
    <div>
      <select (change)="onTypeChange($event.target.value)" *ngIf="types$ | async as types"
        class="form-control digits col-xl-9 col-sm-7">
        <option>Chọn Lĩnh vực</option>
        <option *ngFor="let type of types" [value]="type.id">
          {{ type.title }}
        </option>
      </select>
    </div>

    <div>
      <select (change)="onCategoryChange($event.target.value)" *ngIf="categories$ | async as categories"
        class="form-control digits col-xl-9 col-sm-7">
        <option>Chọn Loại Sản Phẩm</option>
        <option *ngFor="let category of categories" [value]="category.id">
          {{ category.title }}
        </option>
      </select>
    </div>

    <div class="btn-popup pull-right">
      <a class="btn btn-primary" routerLink="../add-product"> Thêm </a>
    </div>
  </div>

  <div class="row products-admin ratio_asos" *ngIf="product_list.length > 0">
    <div class="col-xl-3 col-sm-6" *ngFor="let product of product_list">
      <div class="card">
        <div class="card-body product-box">
          <div class="img-wrapper">
            <div class="lable-block">
              <span class="lable3" *ngIf="product.new">new</span>
              <span class="lable4" *ngIf="product.sale == 'on sale'">on sale</span>
            </div>
            <div class="front">
              <a href="javascript:void(0)"><img [src]="product.images[0].src" class="img-fluid blur-up lazyload bg-img"
                  alt="" /></a>
              <div class="product-hover">
                <ul>
                  <li>
                    <a href="javascript:void(0)" [routerLink]="'../edit-product/' + product.id" class="btn"
                      type="button" data-original-title="" title="">
                      <i class="icon-pencil-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" [routerLink]="'../product-detail/' + product.id" class="btn"
                      type="button" data-original-title="" title="">
                      <i class="icon-search"></i>
                    </a>
                  </li>
                  <li>
                    <button class="btn" type="button" data-toggle="modal" data-target="#exampleModalCenter"
                      data-original-title="" title="" (click)="deleteProduct(product.id)">

                      <i class="icon-trash"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="product-detail">
            <div class="rating">
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i> <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
            <a href="javascript:void(0)" [routerLink]="'../product-detail/' + product.id">
              <h6>{{ product.title }}</h6>
            </a>
            <h4>
              {{ product.discount }} <del>{{ product.price }}</del>
            </h4>
            <ul class="color-variant">
              <li class="bg-light0"></li>
              <li class="bg-light1"></li>
              <li class="bg-light2"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
