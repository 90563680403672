<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Thêm sản phẩm</h5>
        </div>
        <div class="card-body">
          <div class="row product-adding">
            <div class="col-xl-5">
              <div class="add-product">
                <div class="row">
                  <div class="col-xl-9 xl-50 col-sm-6 col-9">
                    <img
                      [src]="currentUrl"
                      alt=""
                      class="img-fluid image_zoom_1"
                    />
                  </div>
                  <div class="col-xl-3 xl-50 col-sm-6 col-3">
                    <ul class="file-upload-product">
                      <li *ngFor="let item of url; let i = index">
                        <div class="box-input-file">
                          <upload-task
                            #uploadTask
                            dirName="product"
                            showImageUploaded="false"
                            showImageDownload="false"
                            (getDownloadUrl)="getDownloadedUrl($event, i)"
                          >
                          </upload-task>
                          <input
                            class="upload"
                            type="file"
                            (change)="readUrl($event, i)"
                          />
                          <img class="imgUpload" alt="" [src]="item.img" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7" *ngIf="productForm">
              <form
                (ngSubmit)="onSubmit()"
                class="needs-validation"
                [formGroup]="productForm"
                novalidate
              >
                <div class="add-product-form">
                  <div class="form-group row">
                    <label
                      for="exampleFormControlSelect1"
                      class="col-xl-3 col-sm-4 mb-0"
                    >
                      Chọn lĩnh vực :</label
                    >
                    <select
                      (change)="onTypeChange($event.target.value)"
                      [formControl]="productForm.controls['type']"
                      class="form-control digits col-xl-8 col-sm-7"
                      id="exampleFormControlSelect1"
                      *ngIf="types$ | async as types"
                    >
                      <option>Chọn Lĩnh vực</option>
                      <option *ngFor="let type of types" [value]="type.id">
                        {{ type.title }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group row">
                    <label
                      for="exampleFormControlSelect1"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Chọn loại sản phẩm :</label
                    >
                    <select
                      *ngIf="categories$ | async as categories"
                      [formControl]="productForm.controls['category']"
                      class="form-control digits col-xl-8 col-sm-7"
                      id="exampleFormControlSelect1"
                    >
                      <option>Chọn Loại sản phẩm</option>
                      <option *ngFor="let cate of categories" [value]="cate.id">
                        {{ cate.title }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label
                      for="validationCustom01"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Tên sản phẩm :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom01"
                      type="text"
                      [formControl]="productForm.controls['title']"
                      required
                    />
                    <div
                      *ngIf="
                        productForm.controls.title.touched &&
                        productForm.controls.title.errors?.required
                      "
                      class="text text-danger"
                    >
                      Nhập tên sản phẩm .
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="validationCustom02"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Giá :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom02"
                      type="number"
                      [formControl]="productForm.controls['price']"
                      required=""
                    />
                    <div
                      *ngIf="
                        productForm.controls.price.touched &&
                        productForm.controls.price.errors?.required
                      "
                      class="text text-danger"
                    >
                      Nhập giá sản phẩm
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="validationCustom02"
                      class="col-xl-3 col-sm-4 mb-0"
                    >
                      Khuyến mãi :</label
                    >
                    <input
                      class="form-control col-xl-1 col-sm-7"
                      type="checkbox"
                      [formControl]="productForm.controls['sale']"
                    />
                  </div>

                  <div class="form-group row">
                    <label
                      for="validationCustom02"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Giảm giá (%) :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      type="number"
                      [formControl]="productForm.controls['discount']"
                    />
                  </div>

                  <div class="form-group row">
                    <label
                      for="validationCustom02"
                      class="col-xl-3 col-sm-4 mb-0"
                    >
                      Sản phẩm mới :</label
                    >
                    <input
                      class="form-control col-xl-1 col-sm-7"
                      type="checkbox"
                      [formControl]="productForm.controls['new']"
                    />
                  </div>

                  <div class="form-group row">
                    <label
                      for="validationCustom03"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Mã sản phẩm :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom03"
                      type="number"
                      [formControl]="productForm.controls['code']"
                      required=""
                    />
                  </div>

                  <!-- <div class="form-group row">
                    <label class="col-xl-3 col-sm-4 mb-0">Số lượng sản phẩm:</label>
                    <div class="qty-box1">
                      <div class="input-group">
                        <i class="fa fa-minus btnGtr1" (click)="decrement()"></i>
                        <input [formControl]="productForm.controls['stock']" class="touchspin1 text-center"
                          name="counter" [value]="counter" type="text" />
                        <i class="fa fa-plus btnLess1" (click)="increment()"></i>
                      </div>
                    </div>
                  </div> -->

                  <div class="form-group row">
                    <label
                      for="validationCustomstock"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Số lượng sản phẩm:</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      type="number"
                      [formControl]="productForm.controls['stock']"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-sm-4">Mô tả :</label>
                    <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                      <quill-editor
                        [formControl]="productForm.controls['description']"
                        [modules]="quillModules"
                      >
                      </quill-editor>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                    <button type="submit" class="btn btn-primary mr-1">
                      Lưu
                    </button>
                    <a [routerLink]="'../product-list'" class="btn btn-light"
                      >Bỏ qua</a
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
