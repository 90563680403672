<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Loại Sản Phẩm</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="pull-left">
              <upload-task #uploadTask dirName="category" showImageUploaded="false" showImageDownload="false"
                (getDownloadUrl)="getDownloadedUrl($event)">
              </upload-task>
            </div>
          </div>



          <div class="btn-popup pull-right">
            <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
              data-target="#exampleModal" (click)="open(content)">
              Thêm
            </button>

            <!-- Add category form -->
            <ng-template #content let-modal>
              <div class="modal-header">
                <h5 class="modal-title f-w-600" id="exampleModalLabel">
                  Thêm Loại Sản Phẩm
                </h5>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="needs-validation" #categoryForm="ngForm">
                  <div class="form">


                    <div class="form-group">
                      <label for="validationCustom01" class="mb-1">Tên chuyên mục :</label>
                      <input [(ngModel)]="category.title" class="form-control" id="validationCustom01" type="text"
                        name="title" placeholder="Tên chuyên mục  " />
                    </div>
                    <div class="form-group mb-0">
                      <label for="validationCustom02" class="mb-1">Hình ảnh :</label>
                      <input [(ngModel)]="category.imageUrl" name="imageUrl" #fileInput
                        (change)="onChooseFile($event.target.files)" class="form-control" id="validationCustom02"
                        type="file" />
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="modal.close('Yes')">
                  Lưu
                </button>
                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                  (click)="modal.dismiss('Cross click')">
                  Close
                </button>
              </div>
            </ng-template>


          </div>

          <div *ngIf="categories" id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <ng2-smart-table [settings]="settings" [source]="categories" allow
                (deleteConfirm)="onDeleteConfirm($event)" (editConfirm)="onEdit($event)"
                (userRowSelect)="onUserRowSelect($event)">
              </ng2-smart-table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
