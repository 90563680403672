import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { EditProductComponent } from './physical/edit-product/edit-product.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'category',
        component: CategoryComponent,
        data: {
          title: 'Loại sản phẩm ',
          breadcrumb: 'Category',
        },
      },
      {
        path: 'sub-category',
        component: SubCategoryComponent,
        data: {
          title: 'Sub Category',
          breadcrumb: 'Sub Category',
        },
      },
      {
        path: 'product-list',
        component: ProductListComponent,
        data: {
          title: 'Danh sách   ',
          breadcrumb: 'Product List',
        },
      },
      {
        path: 'product-detail/:productId',
        component: ProductDetailComponent,
        data: {
          title: 'Chi tiết sản phẩm ',
          breadcrumb: 'Chi tiết sản phẩm ',
        },
      },
      {
        path: 'edit-product/:productId',
        component: EditProductComponent,
        data: {
          title: 'Chi tiết sản phẩm ',
          breadcrumb: 'Chi tiết sản phẩm ',
        },
      },
      {
        path: 'add-product',
        component: AddProductComponent,
        data: {
          title: 'Thêm sản phẩm ',
          breadcrumb: 'Thêm sản phẩm ',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule { }
