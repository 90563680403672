<ng-template class="theme-modal" #content let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body">
      <button
        type="button"
        class="close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row">
        <div class="col-lg-12">
          <div class="col-xl-7" *ngIf="productForm">
            <form
              (ngSubmit)="onSubmit()"
              class="needs-validation"
              [formGroup]="productForm"
              novalidate
            >
              <div class="add-product-form">
                <div class="form-group row">
                  <label
                    for="exampleFormControlSelect2"
                    class="col-xl-3 col-sm-4 mb-0"
                    >Chọn trạng thái đơn hàng :</label
                  >
                  <select
                    *ngIf="listOrderStatus"
                    [formControl]="productForm.controls['orderStatus']"
                    class="form-control digits col-xl-8 col-sm-7"
                    id="exampleFormControlSelect2"
                  >
                    <option
                      *ngFor="let status of listOrderStatus"
                      [value]="status.status"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group row">
                  <label for="validationCustom02" class="col-xl-3 col-sm-4 mb-0"
                    >Phí vận chuyển :</label
                  >
                  <input
                    class="form-control col-xl-8 col-sm-7"
                    id="validationCustom02"
                    type="number"
                    [formControl]="productForm.controls['shippingFee']"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                  <!-- <button type="submit" class="btn btn-primary mr-1">
                    Lưu
                  </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onSubmit()">
        Lưu
      </button>
      <button
        type="button"
        class="btn btn-outline-red"
        (click)="modal.close('Close click')"
      >
        Đóng
      </button>
    </div>
  </div>
</ng-template>
