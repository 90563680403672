import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from 'src/app/shared/models/product.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { ToastrService } from 'ngx-toastr';

// Product Details Main Slider
export let ProductDetailsMainSlider: any = {
  items: 1,
  nav: false,
  dots: false,
  autoplay: false,
  slideSpeed: 300,
  loop: true,
};

// Product Details Thumb Slider
export let ProductDetailsThumbSlider: any = {
  items: 3,
  loop: true,
  margin: 10,
  dots: false,
};

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [NgbRatingConfig],
})
export class ProductDetailComponent implements OnInit {
  public closeResult: string;
  public counter: number = 1;

  public imagesRect: Image[] = [
    new Image(
      0,
      { img: 'assets/images/pro3/2.jpg' },
      { img: 'assets/images/pro3/1.jpg' }
    ),
    new Image(
      1,
      { img: 'assets/images/pro3/27.jpg' },
      { img: 'assets/images/pro3/27.jpg' }
    ),
    new Image(
      2,
      { img: 'assets/images/pro3/1.jpg' },
      { img: 'assets/images/pro3/1.jpg' }
    ),
    new Image(
      3,
      { img: 'assets/images/pro3/2.jpg' },
      { img: 'assets/images/pro3/2.jpg' }
    ),
  ];

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

  public activeSlide: any = 0;

  productId = '';
  product$: Observable<Product>;


  constructor(
    private modalService: NgbModal,
    config: NgbRatingConfig,
    private _activatedRoute: ActivatedRoute,
    public productService: ProductService,
    private _router: Router,
    private toastrService: ToastrService
  ) {
    config.max = 5;
    config.readonly = false;
  }

  ngOnInit() {
    this.productId = this._activatedRoute.snapshot.paramMap.get('productId');

    this.product$ = this.productService.getProduct(this.productId);
  }

  deleteProduct() {
    if (window.confirm('Bạn có muốn xóa không?')) {
      this.productService.deleteProduct(this.productId);
      this.toastrService.success("Đã xóa thành công !", "Thông báo")

      this._router.navigate(['../products/product-list']);


    }

  }

  editProduct() {
    this._router.navigate(['../products/edit-product', this.productId]);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }
}
