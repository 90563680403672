import { content } from './../../../shared/routes/content-routes';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { BlogCate } from 'src/app/shared/models/blog.model';
import { BlogsService } from 'src/app/shared/service/blogs.service';
import { Store } from '@ngxs/store';
import { User } from 'src/app/shared/models/user.model';

import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss'],
})
export class AddBlogComponent implements OnInit {
  @ViewChild('uploadTask', { static: false }) uploadTask: UploadTaskComponent;

  public productForm: FormGroup;
  public counter: number = 1;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };
  currentUrl = 'assets/images/prod-bg.jpg';

  categories$: Observable<BlogCate[]>;

  downloadedUrl: string = null;
  user: User;

  constructor(
    private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private _blogsService: BlogsService,
    private toastrService: ToastrService,
    private _store: Store
  ) {
    this.productForm = this.fb.group({
      title: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
        ],
      ],
      authorId: [''],

      description: [''],
      content: ['  '],
    });
  }

  ngOnInit() {
    this.categories$ = this._blogsService.getCategories();
    this._store
      .select((state) => state.auth.user)
      .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  onSubmit(): void {
    if (this.downloadedUrl) {
      const blog = this.productForm.value;
      blog.imageUrl = this.downloadedUrl;
      blog.author = {
        authorId: this.user.uid,
        displayName: this.user.displayName,
        email: this.user.email,
        photoURL: this.user.photoURL,
      };
      blog.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      this._blogsService.addBlog(blog);
      this.productForm.reset();
      this.toastrService.success('Đã thêm thành công !', 'Thông báo ');
      this.downloadedUrl = null;
      this.productForm.reset();
      this._changeDetectorRef.markForCheck();
    } else {
      this.toastrService.warning('Bạn cần chọn hình ảnh cho sản phẩm!');
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  chooseUpload(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentUrl = reader.result.toString();
    };

    // start upload
    this.uploadTask.startUpload(event.target.files[0]);
  }

  getDownloadedUrl(value): void {
    this.downloadedUrl = value;
  }
}
