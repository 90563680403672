import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore';
import { Observable, Observer, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileObj } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  /**
   * Constructor
   */
  constructor(private _afs: AngularFirestore) {}

  getFile(fileId: string): Observable<any> {
    return this._afs
      .collection('files')
      .doc(fileId)
      .valueChanges({ idField: 'id' });
  }

  async addFile(data: any): Promise<void> {
    let id = this._afs.createId();

    const filesRef = this._afs.collection('files');
    filesRef.doc(id).set(data);
  }

  getFileByPath(path: string): Promise<any> {
    return new Promise((resolve, reject) =>
      this._afs
        .collection('files', (ref) => ref.where('path', '==', path))
        .snapshotChanges()
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as FileObj;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        )
    );
  }

  deleteFile(fileId: string): Promise<void> {
    return this._afs.collection('files').doc(fileId).delete();
  }
}
