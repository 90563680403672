import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { DiscountPipe } from './pipes/discount.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProvinceService } from './service/province.service';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { EditOrderComponent } from './components/modal/edit-order/edit-order.component';
import { UpdateOrderComponent } from './components/modal/edit-order/update-order/update-order.component';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    DiscountPipe,
    DiscountPipe,
    OrderDetailComponent,
    EditOrderComponent,
    UpdateOrderComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  providers: [NavService, WINDOW_PROVIDERS, ProvinceService],
  exports: [
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    DiscountPipe,
    DiscountPipe,
    OrderDetailComponent,
    EditOrderComponent,
    UpdateOrderComponent,
  ],
})
export class SharedModule {}
