import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import firebase from 'firebase/compat/app';
import { Order } from '../models/order.model';
import { Utils } from '../utils';

const state = {
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]'),
};

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private _afs: AngularFirestore
  ) {}

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  // Create order
  public createOrder(
    product: any,
    details: any,
    orderId: any,
    amount: any,
    userId: string,
    paymentMethod: any,
    paymentStatus
  ): Promise<any> {
    console.log(amount);
    var item: Order = {
      shippingDetails: details,
      product: product,
      orderId: orderId,
      totalAmount: amount,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      paymentStatus: paymentStatus,
      paymentMethod: paymentMethod,
      orderStatus: 'PROCESSING',
      userId: userId,
    };

    return this._afs
      .collection('orders')
      .doc(orderId)
      .set({ ...item });
  }

  getOrder(orderId: string): Observable<any> {
    return this._afs
      .collection('orders')
      .doc(orderId)
      .valueChanges({ idField: 'id' });
  }

  getOrders(): Observable<any[]> {
    return this._afs.collection('orders').valueChanges({ idField: 'id' });
  }

  getOrdersTransactions(): Observable<any[]> {
    return this._afs
      .collection<Order>('orders', (ref) =>
        ref.where('paymentTxnId', '!=', null)
      )
      .valueChanges({ idField: 'id' });
  }

  getOrdersOfUser(userId: string): Observable<Order[]> {
    return this._afs
      .collection<Order>('orders', (ref) => ref.where('userId', '==', userId))
      .valueChanges({ idField: 'id' });
  }

  getOrderStatusName(stasusCode: string): string {
    if (stasusCode !== '')
      return Utils.orderStatus.filter((item) => item.status == stasusCode)[0]
        .name;
    else return 'Mới tiếp nhận  ';
  }

  updateOder(id: string, data: any): Promise<void> {
    return this._afs
      .collection('orders')
      .doc(id)
      .update({ ...data });
  }
}
