import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Order } from 'src/app/shared/models/order.model';

import { environment } from 'src/environments/environment';
import { OrderService } from '../../service/order.service';
import { ProductService } from '../../service/product.service';
import { ProvinceService } from '../../service/province.service';

import { Utils } from '../../utils';
import { UpdateOrderComponent } from '../modal/edit-order/update-order/update-order.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  @ViewChild('updateOrder') updateOrder: UpdateOrderComponent;
  @Input() orderId: string = '';

  orderDetails$: Observable<Order>;

  provinces: any;
  districts: any;
  communes: any;
  loading = true;
  shipDate;

  constructor(
    public productService: ProductService,
    public orderService: OrderService,
    private _activatedRoute: ActivatedRoute,
    private _provinceService: ProvinceService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    // delete cartItem
    localStorage.removeItem('cartItems');

    if (this.orderId) {
      this.orderDetails$ = this.orderService.getOrder(this.orderId);
      this.orderDetails$.pipe(take(2)).subscribe((orderDetails: any) => {
        if (orderDetails) {
          this._provinceService
            .getProvinceNameByCode(orderDetails.shippingDetails.province)
            .subscribe((data) => {
              console.log(data);
              this.provinces = data[0].name;
            });

          this._provinceService
            .getDistrictNameByCode(orderDetails.shippingDetails.district)
            .pipe(take(1))
            .subscribe((data) => {
              this.districts = data[0].name;
            });
          this._provinceService
            .getCommuneNameByCode(orderDetails.shippingDetails.commune)
            .pipe(take(1))
            .subscribe((data) => {
              this.communes = data[0].name;
              this.loading = false;
            });

          // ship date
          this.shipDate = Utils.addDaysAndConvertDateString(
            orderDetails.timestamp.toDate(),
            5
          );
        }
      });
    } else {
    }
  }

  openNewModal() {
    this.updateOrder.openModal();
  }
}
