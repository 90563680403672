<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Thêm bài viết mới</h5>
        </div>
        <div class="card-body">
          <form
            (ngSubmit)="onSubmit()"
            class="needs-validation"
            [formGroup]="productForm"
            novalidate
          >
            <div class="row product-adding">
              <div class="col-xl-4">
                <div class="add-product">
                  <div class="row">
                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                      <upload-task
                        #uploadTask
                        dirName="product"
                        showImageUploaded="false"
                        showImageDownload="false"
                        (getDownloadUrl)="getDownloadedUrl($event)"
                      >
                      </upload-task>
                      <br />

                      <img
                        height="200px"
                        width="auto"
                        [src]="currentUrl"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                      <ul class="file-upload-product">
                        <li>
                          <div class="box-input-file">
                            <input
                              class="upload"
                              type="file"
                              (change)="chooseUpload($event)"
                            />
                            <img
                              class="imgUpload img-fluid image_zoom_1"
                              alt=""
                              src="assets/images/upload.png"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8">
                <div class="add-product-form">
                  <div class="form-group row">
                    <label
                      for="validationCustom01"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Tựa đề :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom01"
                      type="text"
                      [formControl]="productForm.controls['title']"
                      required
                    />
                    <div
                      *ngIf="
                        productForm.controls.title.touched &&
                        productForm.controls.title.errors?.required
                      "
                      class="text text-danger"
                    >
                      Cần nhập tựa đề tin tức .
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="validationCustom02"
                      class="col-xl-3 col-sm-4 mb-0"
                      >Mô tả ngắn :</label
                    >
                    <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                      <textarea
                        class="form-control"
                        id="validationCustom02"
                        rows="10"
                        [formControl]="productForm.controls['description']"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row product-adding mt-5">
              <div class="col-xl-12">
                <div class="add-product-form">
                  <div class="form-group row">
                    <label for="content" class="col-xl-12">Nội dung :</label>
                    <div class="col-xl-12">
                      <quill-editor
                        id="content"
                        [formControl]="productForm.controls['content']"
                        [modules]="quillModules"
                      >
                      </quill-editor>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <div
                    class="product-buttons col-sm-10 offset-xl-10 offset-sm-8"
                  >
                    <button type="submit" class="btn btn-primary mr-1">
                      Lưu
                    </button>
                    <a [routerLink]="'../list'" class="btn btn-light">Bỏ qua</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
