import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { User } from 'src/app/shared/models/user.model';
import firebase from 'firebase/compat/app';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BlogsService } from 'src/app/shared/service/blogs.service';
@Component({
  selector: 'app-edit-about',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @ViewChild('uploadTask', { static: false }) uploadTask: UploadTaskComponent;

  public productForm: FormGroup;

  currentUrl = 'assets/images/1920_1080.jpg';

  downloadedUrl: string = null;
  srcVideoEmbed;

  constructor(
    private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService,
    private _afs: AngularFirestore,
    public blogsService: BlogsService
  ) {}

  onSubmit(): void {
    if (this.downloadedUrl) {
      const video = this.productForm.value;

      video.imageUrl = this.downloadedUrl;
      this._afs
        .collection('video')
        .doc('1')
        .update({ ...video });
      this.productForm.reset();
      this.toastrService.success('Đã cập nhật thành công !', 'Thông báo ');
      this.downloadedUrl = null;
      this.productForm.reset();
      this._changeDetectorRef.markForCheck();
    } else {
      this.toastrService.warning('Bạn cần chọn hình ảnh !');
    }
  }

  //FileUpload
  chooseUpload(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentUrl = reader.result.toString();
    };

    // start upload
    this.uploadTask.startUpload(event.target.files[0]);
  }

  getDownloadedUrl(value): void {
    this.downloadedUrl = value;
  }

  video$: Observable<any>;
  video: any;

  ngOnInit() {
    this.video$ = this._afs.collection('video').doc('1').valueChanges();

    this.video$.subscribe((data) => {
      this.video = data;
      if (this.video.imageUrl) {
        this.currentUrl = this.video.imageUrl;
        this.downloadedUrl = this.video.imageUrl;
      }

      this.productForm = this.fb.group({
        videoId: [
          this.video.videoId,
          [
            Validators.required,
            Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
          ],
        ],
      });

      this.srcVideoEmbed =
        'https://www.youtube.com/embed/' +
        this.productForm.controls['videoId'].value;
      this._changeDetectorRef.markForCheck();
    });
  }
}
