
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  usersRef = this.afs.collection('users');
  followingRef = this.afs.collection('following');
  followersRef = this.afs.collection('followers');

  booksRef = this.afs.collection('books');
  likesRef = this.afs.collection('likes');
  activitiesRef = this.afs.collection('activities');
  commentsRef = this.afs.collection('comments');
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);


  /**
   * Constructor
   */
  constructor(
    private afs: AngularFirestore,

    private _httpClient: HttpClient,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._httpClient.get<User>('api/common/user').pipe(
      tap((user) => {
        this._user.next(user);
      })
    );
  }

  updateUserStatus(userId: string, status: string): Promise<any> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userId}`);
    return userRef.set({ status: status }, { merge: true });
  }

  updateUserTheme(userId: string, theme: string): Promise<any> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userId}`);
    return userRef.set({ theme: theme }, { merge: true });
  }

  updateUserScheme(userId: string, scheme: string): Promise<any> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userId}`);
    return userRef.set({ scheme: scheme }, { merge: true });
  }


  updateUser(user: User): Promise<any> {
    // update user
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(user, { merge: true });
  }

  getUserInfo(userId): Observable<User> {
    return this.afs.doc<User>(`users/${userId}`).valueChanges();
  }



  isFollowingUser(currentUserId, userId): Observable<any> {

    return this.followersRef
      .doc(userId)
      .collection('userFollowers')
      .doc(currentUserId).valueChanges();


  }


  getAllUsersByCount(count: number): Observable<any> {

    return this.afs.collection<User>('users',
      ref => ref
        .orderBy('email')
        .limit(count)).valueChanges({ idField: 'uid' });


  }

  getFollowersOfUserByCount(userId: string, count: number): Observable<any> {

    return this.followersRef
      .doc(userId)
      .collection('userFollowers').valueChanges({ idField: 'uid' });
  }

  getFollowingsOfUserByCount(userId: string, count: number): Observable<any> {

    return this.followingRef
      .doc(userId)
      .collection('userFollowing').valueChanges({ idField: 'uid' });
  }

  getAllUsers(): Observable<any> {

    return this.afs.collection<User>('users',
      ref => ref
        .orderBy('email')).valueChanges({ idField: 'uid' });


  }




  // likeBook(currentUser: any, book: Book): void {

  //     const bookRef = this.booksRef
  //         .doc(book.id);

  //     bookRef.get()
  //         .subscribe((doc) => {
  //             const likeCount = doc.data['likeCount'];
  //             bookRef.update({ 'likeCount': likeCount + 1 });

  //             this.likesRef
  //                 .doc(book.id)
  //                 .collection('bookLikes')
  //                 .doc(currentUser.uid)
  //                 .set({});
  //             this.addActivityItem(currentUser, book, null, Utils.activityTypeLike, null, null, null);
  //         });
  // }

  // unlikeBook(currentUser: any, book: Book): void {

  //     const bookRef = this.booksRef
  //         .doc(book.id);

  //     bookRef.get().subscribe((doc) => {
  //         const likeCount = doc.data['likeCount'];
  //         bookRef.update({ 'likeCount': likeCount - 1 });

  //         this.likesRef
  //             .doc(book.id)
  //             .collection('bookLikes')
  //             .doc(currentUser.uid)
  //             .get()
  //             .subscribe((booLikeDoc) => {
  //                 if (booLikeDoc.exists) {
  //                     booLikeDoc.ref.delete();
  //                 }
  //             });

  //         //this.addActivityItem(currentUser, book.id, book, null, false, null);
  //     });


  // }

  // async didLikePost(currentUserId: string, book: Book): Promise<any> {
  //     const userDoc = await this.likesRef
  //         .doc(book.id)
  //         .collection('bookLikes')
  //         .doc(currentUserId).get();

  //     return userDoc.subscribe(value => value.exists);
  // }

  // commentOnBook(user, bookId: string, book: Book, comment: string): void {

  //     const _user = {
  //         uid: user.uid,
  //         displayName: user.displayName,
  //         photoURL: user.photoURL
  //     };

  //     this.commentsRef.doc(bookId).collection('bookComments').add(
  //         {
  //             content: comment,
  //             user: _user,
  //             timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //         }
  //     );
  //     this.addActivityItem(user, book, comment,
  //         Utils.activityTypeComment, null, null, null);
  // }

  // addActivityItem(currentUser, book: Book, comment: string,
  //     activityType: string, review: string, rating: string, quote: string): void {

  //     this.activitiesRef.add(
  //         {
  //             userId: currentUser.uid,
  //             photoURL: currentUser.photoURL,
  //             displayName: currentUser.displayName,
  //             bookId: book.id,
  //             bookImageUrl: book.imageUrl,
  //             comment: comment,
  //             activityType: activityType,
  //             review: review,
  //             rating: rating,
  //             quote: quote,
  //             timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //         }
  //     );

  // }

  // addActivityItemWithBookId(currentUser, bookId: string, comment: string,
  //     activityType: string, review: string, rating: string, quote: string): void {

  //     var book$ = this._booksService.getBook(bookId).pipe(take(1));
  //     book$.subscribe(book => {
  //         this.activitiesRef.add(
  //             {
  //                 userId: currentUser.uid,
  //                 photoURL: currentUser.photoURL,
  //                 displayName: currentUser.displayName,
  //                 bookId: book.id,
  //                 bookImageUrl: book.imageUrl,
  //                 comment: comment,
  //                 activityType: activityType,
  //                 review: review,
  //                 rating: rating,
  //                 quote: quote,
  //                 timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  //             }
  //         );

  //     })

  // }



  // getActivitiesOfBook(bookId): Observable<any> {
  //     return this.afs.collection('activities',
  //         ref => ref.where('bookId', '==', bookId)
  //             .orderBy('timestamp')
  //             .limit(20)).valueChanges();

  // }

  // getActivitiesOfUsers(userId): Observable<any> {
  //     return this.afs.collection('activities',
  //         ref => ref.where('userId', '==', userId)
  //             .orderBy('timestamp')
  //             .limit(20)).valueChanges();

  // }


  // getCommentsOfBook(bookId): Observable<any> {
  //     return this.commentsRef
  //         .doc(bookId)
  //         .collection('bookComments',
  //             ref => ref
  //                 .orderBy('timestamp')
  //                 .limit(20)).valueChanges();

  // }


  // addComingSoon(email: string, module: string): Promise<any> {
  //     const id = this.afs.createId();
  //     const comingSoonsRef = this.afs
  //         .collection('comingsoon');

  //     return new Promise((resolve, reject) =>
  //         this.afs.collection<any>('comingsoon',
  //             ref => ref
  //                 .where('email', '==', email)
  //         ).valueChanges({ idField: 'id' })
  //             .subscribe(items => {

  //                 if (items.length === 0) {

  //                     return comingSoonsRef.doc(id)
  //                         .set({ 'email': email, 'module': module })
  //                         .then(_ => resolve(email));

  //                 } else {

  //                     if (!items[0].module.includes(module)) {

  //                         return comingSoonsRef.doc(id)
  //                             .set({ 'email': email, 'module': module })
  //                             .then(_ => resolve(email));
  //                     }
  //                     else {
  //                         return resolve(null);
  //                     }
  //                 }
  //             })

  //     );


  // }


}
