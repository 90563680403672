import { FileStorageService } from './../../service/file-storage.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import {
  AngularFirestore,
  DocumentSnapshot,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() dirName = 'images';

  @Input() showImageUploaded = true;
  @Input() showImageDownload = true;

  // khai bao tra ve downloadUrl
  @Output() getDownloadUrl = new EventEmitter<string>();
  @Output() getPath = new EventEmitter<string>();

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  fileToUpload: File;

  constructor(
    private storage: AngularFireStorage,
    private _fileStorageService: FileStorageService
  ) {}

  ngOnInit() {
    if (this.file) {
      this.startUpload(this.file);
    }
  }

  startUpload(fileUpload: File) {
    this.fileToUpload = this.file;
    // The storage path
    const path = `${this.dirName}/${fileUpload.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // check exists
    ref
      .getDownloadURL()
      .toPromise()
      .then((response) => {
        // Found it. Do whatever
        this.downloadURL = response;
        this.getDownloadUrl.emit(this.downloadURL);
        this.getPath.emit(path);

        // const _fileData = {
        //   downloadURL: this.downloadURL,
        //   path: path,
        //   folder: this.dirName,
        // };

        // this._fileStorageService.addFile(_fileData);
      })
      .catch((err) => {
        // Didn't exist... or some other error
        // The main task
        this.task = this.storage.upload(path, fileUpload);

        // Progress monitoring
        this.percentage = this.task.percentageChanges();

        this.snapshot = this.task.snapshotChanges().pipe(
          finalize(async () => {
            this.downloadURL = await ref.getDownloadURL().toPromise();

            const _fileData = {
              downloadURL: this.downloadURL,
              path: path,
              folder: this.dirName,
            };

            this._fileStorageService.addFile(_fileData);
            this.getDownloadUrl.emit(this.downloadURL);
            this.getPath.emit(path);
          })
        );
      });
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
