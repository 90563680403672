import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Category, Type } from 'src/app/shared/models/category.model';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  public product_list = [];
  types$: Observable<Type[]>;
  categories$: Observable<Category[]>;

  constructor(
    private _productService: ProductService,
    private _categoriesService: CategoriesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService
  ) {
    this._productService.getProducts().subscribe((products) => {
      this.product_list = products;
    });
  }

  ngOnInit() {
    this.types$ = this._categoriesService.getTypes();
    this.categories$ = this._categoriesService.getCategories();
  }

  onTypeChange(selectedItemId: string) {
    this.categories$ =
      this._categoriesService.getCategoriesByType(selectedItemId);
    this._changeDetectorRef.markForCheck(); //
  }
  onCategoryChange(selectedItemId: string) {
    this._productService
      .getProductsByCategory(selectedItemId)
      .subscribe((products) => {
        this.product_list = products;
        this._changeDetectorRef.markForCheck(); //
      });
  }

  deleteProduct(productId: string) {
    if (window.confirm('Bạn có muốn xóa không?')) {
      this._productService.deleteProduct(productId);
      this.toastrService.success('Đã xóa thành công !', 'Thông báo');
    }
  }
}
