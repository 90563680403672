import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Category, Type } from 'src/app/shared/models/category.model';
import { Product } from 'src/app/shared/models/product.model';
import { BlogsService } from 'src/app/shared/service/blogs.service';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
  public blog_list = [];
  categories$: Observable<Category[]>;

  constructor(
    private _blogsService: BlogsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastrService: ToastrService
  ) {
    this._blogsService.getBlogs().subscribe((blogs) => {
      this.blog_list = blogs;
    });
  }

  ngOnInit() {
    // this.categories$ = this._blogsService.getCategories();
  }

  onCategoryChange(selectedItemId: string) {
    this._blogsService
      .getBlogsByCategory(selectedItemId)
      .subscribe((products) => {
        this.blog_list = products;
        this._changeDetectorRef.markForCheck(); //
      });
  }

  deleteProduct(id: string) {
    if (window.confirm('Bạn có muốn xóa không?')) {
      this._blogsService.deleteBlog(id);
      this.toastrService.success('Đã xóa thành công !', 'Thông báo');
    }
  }
}
