import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UploadTaskComponent } from 'src/app/shared/components/upload-task/upload-task.component';
import { Category, Type } from 'src/app/shared/models/category.model';
import { CategoriesService } from 'src/app/shared/service/categories.service';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  @ViewChild('uploadTask', { static: false }) uploadTask: UploadTaskComponent;

  public productForm: FormGroup;
  public counter: number = 1;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
    ],
  };
  currentUrl = 'assets/images/prod-bg.jpg';
  public url = [
    {
      img: 'assets/images/product/1.jpg',
    },
    {
      img: 'assets/images/product/1.jpg',
    },
    {
      img: 'assets/images/product/1.jpg',
    },
    {
      img: 'assets/images/product/1.jpg',
    },
    {
      img: 'assets/images/product/1.jpg',
    },
  ];

  public images = [];

  variants = [];

  types$: Observable<Type[]>;
  categories$: Observable<Category[]>;

  downloadedUrl: string = null;

  constructor(
    private fb: FormBuilder,
    private _categoriesService: CategoriesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _productsService: ProductService,
    private toastrService: ToastrService
  ) {
    this.productForm = this.fb.group({
      title: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
        ],
      ],
      price: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
        ],
      ],
      sale: [false],
      discount: ['', [Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      stock: ['0', [Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      tags: [''],
      new: [false],
      code: [''],
      category: ['', Validators.required],
      type: ['', Validators.required],
      description: [''],
      brand: [''],
    });
  }

  ngOnInit() {
    this.types$ = this._categoriesService.getTypes();
    this.categories$ = this._categoriesService.getCategories();
  }

  onTypeChange(selectedItemId: string) {
    this.categories$ =
      this._categoriesService.getCategoriesByType(selectedItemId);
    this._changeDetectorRef.markForCheck(); //
  }

  onSubmit(): void {
    if (this.images.length > 0) {
      const product = this.productForm.value;
      product.images = this.images;
      product.variants = this.variants;
      const collection = [this.productForm.value.category];
      product.collection = collection;
      this._productsService.addProduct(product).then(() => {
        this.toastrService.success('Đã cập nhật sản phẩm thành công !');
        this.images = [];
        this.variants = [];
        this.productForm.reset();
        this._changeDetectorRef.markForCheck();
      });
    } else {
      this.toastrService.warning('Bạn cần chọn hình ảnh cho sản phẩm!');
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
      this.currentUrl = reader.result.toString();
    };

    // start upload
    this.uploadTask.startUpload(event.target.files[0]);
    // switch (i) {
    //   case 0:
    //     this.uploadTask0.startUpload(event.target.files[0]);
    //     break;
    //   case 1:
    //     this.uploadTask1.startUpload(event.target.files[0]);
    //     break;
    //   default:
    //     break;
    // }
  }

  getDownloadedUrl(value, i): void {
    const image = {
      id: i,
      image_id: i,
      alt: '',
      src: value,
    };

    const variant = {
      id: i,
      variant_id: i,
      color: '',
      image_id: i,
    };

    this.images.push(image);
    this.variants.push(variant);
  }
}
