<!-- order-detail section start -->

<div class="container">
  <div class="row" *ngIf="loading">
    <h4>Đang tải chi tiết đơn hàng ... !</h4>
    <span
      class="spinner-grow spinner-grow-sm bg-danger"
      role="status"
      aria-hidden="true"
    >
    </span>
  </div>
  <div class="row" *ngIf="orderDetails$ | async as orderDetails">
    <div class="col-lg-6">
      <div class="product-order">
        <h3>Chi tiết đơn hàng của bạn</h3>
        <div
          class="row product-order-detail"
          *ngFor="let product of orderDetails.product"
        >
          <div class="col-3">
            <img [src]="product.images[0].src" alt="" class="img-fluid" />
          </div>
          <div class="col-3 order_detail">
            <div>
              <h4>Sản phẩm</h4>
              <h5>{{ product.title }}</h5>
            </div>
          </div>
          <div class="col-3 order_detail">
            <div>
              <h4>Số lượng</h4>
              <h5>{{ product.quantity }}</h5>
            </div>
          </div>
          <div class="col-3 order_detail">
            <div>
              <h4>Giá</h4>
              <h5>
                {{
                  product?.price * productService?.Currency?.price
                    | discount: product
                    | currency: productService?.Currency.currency:"symbol"
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="total-sec">
          <ul>
            <li>
              Tạm tính
              <span>{{
                orderDetails.productAmount
                  | currency: productService?.Currency.currency:"symbol"
              }}</span>
            </li>
            <li>
              Phí giao hàng
              <span>{{
                orderDetails.shippingFee
                  | currency: productService?.Currency.currency:"symbol"
              }}</span>
            </li>
            <!-- <li>Tax <span>$00.00</span></li> -->
          </ul>
        </div>
        <div class="final-total">
          <h3>
            Tổng cộng
            <span>{{
              orderDetails.totalAmount
                | currency: productService?.Currency.currency:"symbol"
            }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="row order-success-sec">
        <div class="col-sm-6">
          <h4>Thông tin</h4>
          <ul class="order-detail">
            <li>Mã đơn hàng : {{ orderDetails.orderId }}</li>
            <li>
              Ngày :
              {{ orderDetails?.timestamp.toDate() | date: "dd/MM/yyyy" }}
            </li>
            <li>
              Tổng tiền đơn hàng :
              {{
                orderDetails.totalAmount
                  | currency: productService?.Currency.currency:"symbol"
              }}
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <h4>Địa chỉ giao hàng</h4>
          <ul class="order-detail">
            <li>{{ orderDetails.shippingDetails.addressShipping }}</li>

            <li>
              {{ communes }}
            </li>

            <li>{{ districts }}</li>
            <li>
              {{ provinces }}
            </li>
            <li>Điện thoại: {{ orderDetails.shippingDetails.phoneNumber }}</li>
          </ul>
        </div>

        <div class="col-sm-12 payment-mode">
          <h4>Phương thức thanh toán</h4>
          <p>
            {{
              orderDetails.paymentMethod === "COD"
                ? "Trả tiền mặt khi nhận hàng"
                : orderDetails.paymentMethod === "CC"
                ? "Visa, Mastercard, JCB"
                : orderDetails.paymentMethod === "zalopayapp"
                ? "Ví ZaloPay"
                : "Thẻ ATM"
            }}
          </p>
          <ul class="order-detail">
            <li>
              {{
                orderDetails.paymentStatus == true
                  ? "Đã thanh toán thành công"
                  : "Chưa thanh toán "
              }}
            </li>
            <li>Mã giao dịch thanh toán: {{ orderDetails.paymentTxnId }}</li>
          </ul>
        </div>

        <div class="col-md-12">
          <div class="delivery-sec">
            <hr class="border" />
            <h4>Trạng thái đơn hàng</h4>
            <h3>
              {{ orderService.getOrderStatusName(orderDetails.orderStatus) }}
            </h3>
          </div>
        </div>
        <!-- <div class="col-md-12">
          <div class="delivery-sec">
            <h3>Ngày giao hàng dự kiến</h3>
            <h2>
              {{ shipDate }}
            </h2>
          </div>
        </div> -->
        <div class="col-md-12">
          <div class="delivery-sec">
            <hr class="border" />

            <button
              type="button"
              class="btn btn-primary"
              (click)="openNewModal()"
            >
              Cập nhật đơn hàng
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row"></div>
</div>
<!-- Section ends -->
<div *ngIf="orderDetails$ | async as orderDetails">
  <app-update-order #updateOrder [order]="orderDetails"></app-update-order>
</div>
