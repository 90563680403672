<div class="flex flex-col items-center justify-between">

  <div class="flex flex-0 w-30" *ngIf="percentage | async as pct">
    <ngb-progressbar type="success" [value]="pct"></ngb-progressbar>
  </div>



  <div class="flex flex-0 w-30" *ngIf="(snapshot | async) as snap">

    <div class="w-30" *ngIf="downloadURL as url">
      <img hidden="!showImageUploaded" [src]="url">
    </div>

    <!--
        {{ snap.bytesTransferred }} of {{ snap.totalBytes }}

        <mat-progress-bar mode="determinate " [value]="100*snap.bytesTransferred/snap.totalBytes">
        </mat-progress-bar> {{100*snap.bytesTransferred/snap.totalBytes | number}} %



        <div class="flex" *ngIf="downloadURL as url">

            <a hidden="!showImageDownload" [href]="url" target="_blank" rel="noopener">Tải về</a>
        </div>

        <button (click)="task.pause()" class="button is-warning" [disabled]="!isActive(snap)">Pause</button>
        <button (click)="task.cancel()" class="button is-danger" [disabled]="!isActive(snap)">Cancel</button>
        <button (click)="task.resume()" class="button is-info" [disabled]="!(snap?.state === 'paused')">Resume</button> -->

  </div>

</div>
