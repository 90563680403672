<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-xl-10">
      <div class="card">
        <div class="card-body">
          <form
            *ngIf="productForm"
            (ngSubmit)="onSubmit()"
            class="needs-validation"
            [formGroup]="productForm"
            novalidate
          >
            <div class="row product-adding">
              <div class="col-xl-12">
                <div class="row">
                  <div class="col-xl-10 col-10">
                    <upload-task
                      #uploadTask
                      dirName="video"
                      showImageUploaded="false"
                      showImageDownload="false"
                      (getDownloadUrl)="getDownloadedUrl($event)"
                    >
                    </upload-task>
                    <br />

                    <img class="img-fluid" [src]="currentUrl" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="add-product">
                  <div class="col-xl-6">Hình ảnh đại diện Video YouTube</div>
                  <div class="col-xl-2 col-2">
                    <ul class="file-upload-product">
                      <li>
                        <div class="box-input-file">
                          <input
                            class="upload"
                            type="file"
                            (change)="chooseUpload($event)"
                          />
                          <img
                            class="imgUpload img-fluid image_zoom_1"
                            alt=""
                            src="assets/images/upload.png"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-12 mt-2">
                <div class="add-product-form">
                  <div class="form-group row">
                    <label
                      for="validationCustom01"
                      class="col-xl-2 col-sm-4 mb-0"
                    >
                      Video Id ( Mã video Link YouTube ) :</label
                    >
                    <input
                      class="form-control col-xl-8 col-sm-7"
                      id="validationCustom01"
                      type="text"
                      [formControl]="productForm.controls['videoId']"
                      required
                    />
                    <div
                      *ngIf="
                        productForm.controls.videoId.touched &&
                        productForm.controls.videoId.errors?.required
                      "
                      class="text text-danger"
                    >
                      Cần nhập Mã Video Link YouTuBe
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <iframe
                  *ngIf="srcVideoEmbed"
                  [src]="blogsService.trustHtml(srcVideoEmbed)"
                  allowfullscreen
                ></iframe>
              </div>

              <div class="col-xl-12 form-group row mb-0">
                <div class="product-buttons col-sm-10 offset-xl-10 offset-sm-8">
                  <button type="submit" class="btn btn-primary mr-1">
                    Lưu
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
