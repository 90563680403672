import { Blog } from './../models/blog.model';

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlogCate } from '../models/blog.model';
import { Utils } from '../utils';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  constructor(
    private _afs: AngularFirestore,
    private _domSanitizer: DomSanitizer
  ) {}

  addCategory(data: any): Promise<void> {
    const id = this._afs.createId();
    return this._afs
      .collection('blogCate')
      .doc(id)
      .set({ ...data });
  }

  deleteCategory(categoryId: string): any {
    return this._afs.collection('blogCate').doc(categoryId).delete();
  }

  getCategory(categoryId: string): Observable<any> {
    return this._afs.collection('blogCate').doc(categoryId).valueChanges();
  }

  updateCategory(categoryId: string, cate: any): any {
    return this._afs.collection('blogCate').doc(categoryId).update(cate);
  }

  getCategories(): Observable<any> {
    return this._afs
      .collection<BlogCate>('blogCate')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as BlogCate;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  // Blogs
  addBlog(data: any): Promise<void> {
    data.handle = Utils.handleize(data.title);
    const id = this._afs.createId();
    return this._afs
      .collection('blogs')
      .doc(id)
      .set({ ...data });
  }

  getBlogs(): Observable<any> {
    return this._afs
      .collection<Blog>('blogs')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Blog;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getBlog(id: string): Observable<Blog> {
    return this._afs
      .collection<Blog>('blogs')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  public getBlogsByCategory(cateId: string): Observable<Blog[]> {
    return this._afs
      .collection<Blog>('blogs', (ref) => ref.where('category', '==', cateId))
      .valueChanges({ idField: 'id' });
  }

  deleteBlog(id: string): Promise<void> {
    return this._afs.collection('blogs').doc(id).delete();
  }

  updateBlog(id: string, data: any): Promise<void> {
    data.handle = Utils.handleize(data.title);
    return this._afs
      .collection('blogs')
      .doc(id)
      .update({ ...data });
  }

  getVideo(id: string): Observable<any> {
    return this._afs
      .collection('video')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  public trustHtml(content: string): any {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(content);
  }
}
