<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Quản lý đơn hàng</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <input
              type="text"
              class="filter-ngx form-control"
              placeholder="Tìm theo mã đơn hàng ..."
              (keyup)="updateFilter($event)"
            />
            <div *ngIf="order?.length === 0">
              <h3>Không Tìm thấy đơn hàng nào !</h3>
            </div>

            <ngx-datatable
              class="bootstrap"
              [rows]="order"
              [columnMode]="ColumnMode.force"
              [columns]="columns"
              [headerHeight]="50"
              [footerHeight]="50"
              rowHeight="auto"
              [limit]="5"
              [selected]="selected"
              [selectionType]="SelectionType.single"
            >
              <ngx-datatable-column prop="Cập nhật đơn hàng">
                <ng-template
                  ngx-datatable-cell-template
                  let-rowIndex="rowIndex"
                  let-value="selected"
                  let-row="row"
                >
                  <button
                    class="btn"
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    data-original-title=""
                    title=""
                    (click)="edit(row)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="Mã đơn hàng"> </ngx-datatable-column>
              <ngx-datatable-column prop="Ngày"> </ngx-datatable-column>
              <ngx-datatable-column prop="Tổng tiền"> </ngx-datatable-column>
              <ngx-datatable-column prop="Phương thức thanh toán">
              </ngx-datatable-column>
              <ngx-datatable-column prop="Trạng thái đơn hàng">
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</div>

<app-edit-order #editOrder [orderId]="selectedOrderId"></app-edit-order>
