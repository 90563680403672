import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from './windows.service';
// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = false;

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      path: '/dashboard/default',
      title: 'Dashboard',
      icon: 'home',
      type: 'link',
      badgeType: 'primary',
      active: false,
    },
    {
      title: 'Sản phẩm ',
      icon: 'box',
      type: 'sub',
      active: false,
      children: [
        { path: '/products/category', title: 'Loại Sản phẩm ', type: 'link' },
        // {
        //   path: '/products/sub-category',
        //   title: 'Loại sản phẩm con ',
        //   type: 'link',
        // },
        {
          path: '/products/product-list',
          title: 'Danh sách sản phẩm ',
          type: 'link',
        },
        {
          path: '/products/add-product',
          title: 'Thêm sản phẩm ',
          type: 'link',
        },
      ],
    },
    {
      title: 'Bán hàng ',
      icon: 'dollar-sign',
      type: 'sub',
      active: false,
      children: [
        { path: '/sales/orders', title: 'Đơn hàng ', type: 'link' },
        { path: '/sales/transactions', title: 'Giao dịch ', type: 'link' },
      ],
    },
    {
      title: 'Tin tức  ',
      icon: 'box',
      type: 'sub',
      active: false,
      children: [
        { path: '/blogs/category', title: 'Chuyên mục ', type: 'link' },

        {
          path: '/blogs/list',
          title: 'Danh sách Tin tức  ',
          type: 'link',
        },
        {
          path: '/blogs/add-blog',
          title: 'Thêm tin tức ',
          type: 'link',
        },
      ],
    },
    // {
    // 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
    // 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
    // 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
    // 	]
    // },
    // {
    // 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
    // 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
    // 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
    // 	]
    // },
    // {
    //   title: 'Media',
    //   path: '/media',
    //   icon: 'camera',
    //   type: 'link',
    //   active: false,
    // },
    // {
    //   title: 'Menus',
    //   icon: 'align-left',
    //   type: 'sub',
    //   active: false,
    //   children: [
    //     { path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
    //     { path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
    //   ],
    // },
    {
      title: 'Khách hàng',
      icon: 'user-plus',
      type: 'sub',
      active: false,
      children: [
        { path: '/users/list-user', title: 'Danh sách', type: 'link' },
        // { path: '/users/create-user', title: 'Tạo mới', type: 'link' },
      ],
    },
    // {
    //   title: 'Vendors',
    //   icon: 'users',
    //   type: 'sub',
    //   active: false,
    //   children: [
    //     { path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
    //     {
    //       path: '/vendors/create-vendors',
    //       title: 'Create Vendor',
    //       type: 'link',
    //     },
    //   ],
    // },
    // {
    // 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
    // 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
    // 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
    // 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
    // 	]
    // },
    {
      title: 'Báo cáo',
      path: '/reports',
      icon: 'bar-chart',
      type: 'link',
      active: false,
    },
    // {
    //   title: 'Settings',
    //   icon: 'settings',
    //   type: 'sub',
    //   children: [{ path: '/settings/profile', title: 'Profile', type: 'link' }],
    // },
    // {
    // 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
    // },

    {
      title: 'Sửa giới thiệu  ',
      path: '/aboutus',
      icon: 'info',
      type: 'link',
      active: false,
    },

    {
      title: 'Cập nhật Video  ',
      path: '/video',
      icon: 'youtube',
      type: 'link',
      active: false,
    },

    // {
    //   title: 'Thoát',
    //   path: '/auth/logout',
    //   icon: 'log-out',
    //   type: 'link',
    //   active: false,
    // },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
